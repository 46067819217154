<template>
    <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-olive">
            <div class="widget-user-image">
                <i class="fas fa-user-cog" style="font-size:xxx-large;position:absolute"></i>
            </div>
            <h5 class="widget-user-username" style="font-size:20px">{{visita.visita.nombre_operario}}</h5>
            <h5 class="widget-user-desc" style="font-size:15px">{{ $t('general.operario') }}</h5>            
        </div>
        <div class="card-footer p-0 bg-olive">
            <div class="row " >
                <div class="col-md-4 ">
                    <button type="button" v-if="visita.visita.datos.telefono1_siniestrado_visita" v-on:click='llamar' class="btn btn-block btn-warning btn-xs" title="Descripción teléfono 1">{{ visita.visita.datos.telefono1_siniestrado_visita }}</button>
                </div>
                <div class="col-md-4 ">
                    <button type="button" v-if="visita.visita.datos.telefono2_siniestrado_visita" v-on:click='llamar' class="btn btn-block btn-warning btn-xs" title="Descripción teléfono 2">{{visita.visita.datos.telefono2_siniestrado_visita }}</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" v-on:click='email' class="btn btn-block btn-warning btn-xs" title="Email">
                        <i class="fas fa-at"></i>
                    </button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" class="btn btn-block btn-warning btn-xs" @click="chat()" title="Chat">
                        <i class="fas fa-comments"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>  
</template>
<script>  
export default ({
    props: ['id', 'servicio', 'perjudicados', 'asegurado','visita'],
    setup() {     
    },
    data(){
        return {
            urlapu : localStorage.getItem('direccion'),            
        }
    },
    methods: {
            llamar: function () {
               window.open("tel:" + this.visita.visita.movil_operario);
            },
            email: function () {
                window.open("mailto:" + this.visita.visita.email_operario,"_blank","popup");
            },
            chat: function () {                
                var url  = "https://"+this.urlapu+"/modulos/chat/panel.php?vista=vista_chats&destino_chat="+this.visita.visita.operarios_idoperario+"&id_visita="+this.visita.visita.idvisitador+"&id_servicio="+this.$props.id;
                window.open(url, "Chat", "width=800, height=600");
            },
        }
    
})
</script>