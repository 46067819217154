<template>
    <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-purple">
            <div class="widget-user-image">
                <i class="fas fa-house-user" style="font-size:xxx-large;position:absolute"></i>
            </div>
            <h5 class="widget-user-username" style="font-size:20px">{{visita.visita.nombre_cliente }}</h5>
            <h5 v-if="!perjudicado" class="widget-user-desc" style="font-size:15px">{{ $t('general.perjudicado') }}</h5>
            <h5 v-else class="widget-user-desc" style="font-size:15px">{{ $t('general.asegurado') }}</h5>
        </div>
        <div class="card-footer p-0 bg-purple">
            <div class="row ">
                <div class="col-md-4 ">
                    <button v-if="visita.visita.telefono1" type="button" v-on:click='llamar' class="btn btn-block btn-warning btn-xs"
                        :title="visita.visita.telefono1_descripcion">{{visita.visita.telefono1}}</button>
                </div>
                <div class="col-md-4 ">
                    <button v-if="visita.visita.telefono2" type="button" v-on:click='llamar' class="btn btn-block btn-warning btn-xs"
                        :title="visita.visita.telefono2_descripcion">{{ visita.visita.telefono2}}</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" class="btn btn-block btn-warning btn-xs" title="Email">
                        <i class="fas fa-at"></i>
                    </button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" class="btn btn-block btn-warning btn-xs" title="Chat">
                        <i class="fas fa-comments"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>  
export default ({
    props: ['id', 'servicio','visita'],
    setup() {     
    },
    data(){
        return {
            perjudicado: this.visita.visita.cliente._datos.perjudicado==0,
        }
    },
    methods: {
       llamar: function () {
            window.open("tel:" + this.visita.visita.movil_operario); //REVISAR TELEFONO 
        },
        email: function () {
            window.open("mailto:" + this.visita.visita.email_operario, "_blank", "popup"); //REVISAR EMAIL
        }
    },
})
</script>